export const DATA_DETAIL = `${process.env.REACT_APP_API_HOST}/client/api/product/detail`
export const PRODUCT_CATEGORY_LIST = `${process.env.REACT_APP_API_HOST}/client/api/product/category/list`
export const PRODUCT_LIST = `${process.env.REACT_APP_API_HOST}/client/api/product/list`
export const PATTERN_CATEGORY_LIST = `${process.env.REACT_APP_API_HOST}/client/api/pattern/category/list`
export const PATTERN_LIST = `${process.env.REACT_APP_API_HOST}/client/api/pattern/list`
export const FONT_LIST = `${process.env.REACT_APP_API_HOST}/client/api/font/list`
export const ORDER_CREATE = `${process.env.REACT_APP_API_HOST}/client/api/order/create`
export const UPLOAD = `${process.env.REACT_APP_API_HOST}/client/api/oss/upload`
export const ORDER_LIST = `${process.env.REACT_APP_API_HOST}/client/api/order/list`
export const ORDER_PAY = `${process.env.REACT_APP_API_HOST}/client/api/order/pay`

export const WX_LOGIN = `${process.env.REACT_APP_API_HOST}/client/open/login/wxgz`
export const WX_JSSDK = `${process.env.REACT_APP_API_HOST}/client/open/wxconfig/jssdk`





