import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import http, { wxAppid, redirectUri } from '../../lib/http'
import * as apis from '../../lib/apis'


const Index = () => {
    const l = useLocation()

    useEffect(() => {
        const urlParam = new URLSearchParams(l.search)
        const code = urlParam.get("code")
        if (code) {
            http.post(`${apis.WX_LOGIN}?appid=${wxAppid}&code=${code}`).then((token: any) => {
                window.location.replace(`/entry/${token}`)
            })
        } else {
            window.location.replace(redirectUri)
        }

    }, [])

    return null
}

export default Index