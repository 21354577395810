import patternBtnTouming from '../static/img/pattern_btn_touming.png'

// console.log()


const { fabric } = window
fabric.Object.NUM_FRACTION_DIGITS = 8
fabric.Object.prototype.hasBorders = false
// fabric.Object.prototype.borderDashArray = [5]
// fabric.Object.prototype.borderColor = "#7b7b7b"

let touming_img = document.createElement('img');
touming_img.crossOrigin = "anonymous"
touming_img.src = patternBtnTouming;
touming_img.style.display = 'none'


fabric.Object.prototype.controls.tr.visible = false
fabric.Object.prototype.controls.br.visible = false
fabric.Object.prototype.controls.tl.visible = false
fabric.Object.prototype.controls.bl.visible = false
fabric.Object.prototype.controls.mtr.visible = false
fabric.Object.prototype.controls.mb.visible = false
fabric.Object.prototype.controls.ml.visible = false
fabric.Object.prototype.controls.mr.visible = false
fabric.Object.prototype.controls.mt.visible = false


// fabric.Textbox.prototype.controls.ml.visible = false
// fabric.Textbox.prototype.controls.mr.visible = false
fabric.Textbox.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    render: function (ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(touming_img, -size / 2, -size / 2, size, size);
        ctx.restore();
    },
    cornerSize: 19,
    actionHandler: fabric.controlsUtils.scalingEqually
});
fabric.Textbox.prototype.controls.tr = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 0,
    render: function (ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(touming_img, -size / 2, -size / 2, size, size);
        ctx.restore();
    },
    cornerSize: 19,
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    withConnection: true,
    actionName: 'rotate',
});



//右上角
fabric.Object.prototype.controls.tr = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 0,
    render: function (ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(touming_img, -size / 2, -size / 2, size, size);
        ctx.restore();
    },
    cornerSize: 19,
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    withConnection: true,
    actionName: 'rotate',
});

//右下角
fabric.Object.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    render: function (ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(touming_img, -size / 2, -size / 2, size, size);
        ctx.restore();
    },
    cornerSize: 19,
    actionHandler: fabric.controlsUtils.scalingEqually
});

var cache = {}

class ClientFabric {

    constructor() {
        this.canvaes = {}
        this.canvaeOn = {}
        this.editData = [] //存放确认之后的编辑数据
        this.editMaxNum = 10 //最多可前进和后退次数
    }

    //恢复缓存数据
    async resetDataFromCache(c = "") {
        if (c) {
            const canvas = this.canvaes[c]
            return this.loadFromJSON(canvas, cache[c])
        }
        for (let code in cache) {
            const canvas = this.canvaes[code]
            await this.loadFromJSON(canvas, cache[code])
        }
        this.clearCache()
    }

    //缓存所有画布的数据
    cacheAllData() {
        for (let code in this.canvaes) {
            cache[code] = this.canvaes[code].toJSON()
        }
    }

    //清除缓存
    clearCache() {
        cache = {}
    }

    //获取json数据
    getJsonData() {
        const obj = {}
        for (let code in this.canvaes) {
            obj[code] = this.canvaes[code].toJSON()
        }
        return obj
    }

    //添加编辑数据
    addEditData(j = null) {
        //达到最大数，删掉第一个
        if (this.editData.length === this.editMaxNum) {
            this.editData.splice(0, 1)
        }
        if (j === null) {
            const obj = {}
            for (let code in this.canvaes) {
                obj[code] = this.canvaes[code].toJSON()
            }
            this.editData.push(obj)
            return this.editData.length
        }
        this.editData.push(j)
        return this.editData.length
    }

    //清空编辑的历史数据
    clearEditData() {
        this.editData = []
    }

    //判断是否canvas都是空的
    isEmptyAll() {
        const notEmptyCodes = []
        for (let code in this.canvaes) {
            if (!this.canvaes[code].isEmpty()) {
                notEmptyCodes.push(code)
            }
        }
        return notEmptyCodes
    }

    //清空所有canvas
    clearAll() {
        for (let code in this.canvaes) {
            if (!this.canvaes[code].isEmpty()) {
                this.canvaes[code].clear()
                this.canvaes[code].backgroundColor = "#FFFFFF"
                this.canvaes[code].renderAll()
            }
        }
    }

    //设置canvas底色
    setBackgroundColor(color) {
        for (let code in this.canvaes) {
            this.canvaes[code].clear()
            this.canvaes[code].backgroundColor = color || "#FFFFFF"
            this.canvaes[code].renderAll.bind(this.canvaes[code])
        }
    }

    //获取编辑的数据
    getEditData() {
        return this.editData
    }

    //awaitloadFromJSON
    loadFromJSON = (canvas, data) => {
        return new Promise((resolve, reject) => {
            canvas.loadFromJSON(data, () => {
                canvas.renderAll.bind(canvas)
                resolve()
            })
        })
    }

    //恢复阶段数据
    async recoverEditData(stepNum) {
        try {
            const data = this.editData[stepNum]
            for (let code in data) {
                await this.loadFromJSON(this.canvaes[code], data[code])
            }
        } catch (error) {
            console.log(error)
        }
    }

    //将所有canvastoJSON
    allToJson() {
        const sum = []
        for (let code in this.canvaes) {
            sum.push({
                code,
                data: this.canvaes[code].toJSON(['font_family_url'])
            })
        }
        return sum
    }


    action(id) {
        let _this = this
        return {
            //根据ID向canvas内添加图片
            addImageById(path, scaleWidth) {
                return new Promise((resolve, reject) => {
                    let canvas = _this.canvaes[id]
                    fabric.Image.fromURL(path, (img) => {
                        // img.set("original_img_url","https://file-1254182580.cos.ap-beijing.myqcloud.com/"+patterns[1])
                        // img.set("id",new Date().getTime())
                        img.scaleToWidth(scaleWidth)
                        canvas.add(img).viewportCenterObject(img)
                        resolve(img)
                    }, { crossOrigin: 'anonymous' })
                })
            },
            //根据ID建听canvas
            onCanvas(handle) {
                let on = _this.canvaeOn[id]
                if (on) {
                    return
                }
                _this.canvaeOn[id] = 1
                let canvas = _this.canvaes[id]
                canvas.on('after:render', e => {
                    const curobj = canvas.getActiveObject()
                    const position = curobj ? {
                        tl_position: {
                            left: curobj?.oCoords.tl.x - 9.5,
                            top: curobj?.oCoords.tl.y - 9.5
                        }, br_position: {
                            left: curobj?.oCoords.br.x - 9.5,
                            top: curobj?.oCoords.br.y - 9.5
                        }, tr_position: {
                            left: curobj?.oCoords.tr.x - 9.5,
                            top: curobj?.oCoords.tr.y - 9.5
                        }, bl_position: {
                            left: curobj?.oCoords.bl.x - 9.5,
                            top: curobj?.oCoords.bl.y - 9.5
                        }
                    } : null
                    handle(e, position, 'after:render')
                })
                canvas.on('object:modified', e => {
                    handle(e, null, 'object:modified')
                })
                canvas.on('path:created', e => {
                    handle(e, null, 'object:modified')
                })
                canvas.on('selection:created', e => {
                    handle(e, null, 'object:modified')
                })
                canvas.on('selection:updated', e => {
                    handle(e, null, 'object:modified')
                })
                canvas.on('selection:cleared', e => {
                    handle(e, null, 'object:modified')
                })
                // canvas.on('mouse:move', (option) => {
                //     if (option.target && option.target.isBrush) {
                //         // 触发你想要监听的行为 
                //         handle(option, null)
                //     }
                // })
            },
            //添加文字
            addText(txt) {
                const canvas = _this.canvaes[id]
                const textbox = new fabric.Text(txt, {
                    fontSize: 50,
                    editable: false
                });
                canvas.add(textbox).viewportCenterObject(textbox).setActiveObject(textbox)
                return textbox
            },
            //加载json数据
            async loadDataFromJson(d) {
                const canvas = _this.canvaes[id]
                await _this.loadFromJSON(canvas, d)
            },
            //失去焦点
            discardActiveObject() {
                const canvas = _this.canvaes[id]
                canvas?.discardActiveObject()
                canvas?.renderAll()
            },
            //获取fabricjs
            getFabricCanvas() {
                const canvas = _this.canvaes[id]
                return canvas
            },
            //复制对象
            copyObject(onSuccess) {
                const canvas = _this.canvaes[id]
                const obj = canvas.getActiveObject()
                // if(obj.type === "text"){
                //     const ooo = new FontFaceObserver(obj.fontFamily)
                //     ooo.load().then(() =>{
                //         const textbox = new fabric.Text(obj.text, {
                //             fontSize: obj.fontSize,
                //             editable: false,
                //             fontFamily: obj.fontFamily,
                //             fill: obj.fill
                //         });
                //         this.canvaes.get(id).add(textbox).viewportCenterObject(textbox).setActiveObject(textbox);
                //         onSuccess()
                //     })
                //     return
                // }
                const clone = fabric.util.object.clone(obj);
                clone.set("left", clone.left + 15)
                canvas.add(clone).setActiveObject(clone)
                onSuccess()
            },
            //居中
            centerH() {
                const canvas = _this.canvaes[id]
                const obj = canvas.getActiveObject()
                obj.centerH()
                canvas.renderAll()
            },
            //向下移动
            sendToBack() {
                const canvas = _this.canvaes[id]
                const obj = canvas.getActiveObject()
                canvas.sendToBack(obj)
                canvas.renderAll()
            },
            //设置橡皮笔刷
            setErase(width = 2) {
                const canvas = _this.canvaes[id]
                canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
                canvas.freeDrawingBrush.width = width
                canvas.isDrawingMode = true;
            },
            //设置普通笔刷
            setPencilBrush(width = 2, color = "#000") {
                const canvas = _this.canvaes[id]
                canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
                canvas.freeDrawingBrush.width = width
                canvas.freeDrawingBrush.color = color
                canvas.isDrawingMode = true;
            },
            //设置圆形笔刷
            setCircleBrush(width = 2, color = "#000") {
                const canvas = _this.canvaes[id]
                canvas.freeDrawingBrush = new fabric.CircleBrush(canvas);
                canvas.freeDrawingBrush.radius = width / 2
                canvas.freeDrawingBrush.color = color
                canvas.isDrawingMode = true;
            },
            //设置喷雾器笔刷
            setSprayBrush(width = 2, color = "#000") {
                const canvas = _this.canvaes[id]
                canvas.freeDrawingBrush = new fabric.SprayBrush(canvas);
                canvas.freeDrawingBrush.width = width
                canvas.freeDrawingBrush.color = color
                canvas.isDrawingMode = true;
            },
            //取消笔刷模式
            cancelBrush() {
                const canvas = _this.canvaes[id]
                canvas.isDrawingMode = false;
            }

        }
    }

    //初始化加载fabric canvas
    loadCanvas(id) {
        if (this.canvaes[id]) {
            return
        }
        let canvas = new fabric.Canvas(id)
        this.canvaes[id] = canvas
        // canvas.backgroundColor = '#FFFFFF'
        canvas.renderAll()
        canvas.selection = false
        canvas.preserveObjectStacking = true
        return this.action(id)
    }

    //设置所有canvas的宽度
    setWidth(width) {
        for (let code in this.canvaes) {
            let v = this.canvaes[code]
            v.setWidth(width)
            v.setHeight(width)
            v.renderAll()
        }
    }

    //下载canvas图片
    downLoadCanvas(code, name) {
        const canvas = this.canvaes[code]
        //保存为高分辨率图片
        canvas.setDimensions({ width: 4096, height: 4096 });
        let url = canvas.toDataURL()
        var link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
    }

    //下载canvas图片
    toBlob(code, orgCanvasWidth) {
        const canvas = this.canvaes[code]
        const cutpartW = 2048
        //保存为高分辨率图片
        canvas.setDimensions({ width: cutpartW, height: cutpartW });
        const objects = canvas.getObjects()
        //转换坐标
        for (let j = 0; j < objects?.length; j++) {
            let ii = objects[j]
            let float_w = cutpartW
            let resource_float_w = parseFloat(orgCanvasWidth)
            ii.left = float_w * parseFloat(ii.left) / resource_float_w
            ii.top = float_w * parseFloat(ii.top) / resource_float_w
            ii.scaleX = float_w * parseFloat(ii.scaleX) / resource_float_w
            ii.scaleY = float_w * parseFloat(ii.scaleY) / resource_float_w
        }
        canvas.renderAll.bind(canvas)
        let url = canvas.toDataURL()
        return this.dataURLtoBlob(url)
    }

    dataURLtoBlob(dataURL) {
        var byteString = atob(dataURL.split(',')[1]);
        var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }








}

export default ClientFabric