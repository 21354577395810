import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Client3D, { Client3DProps } from "../../lib/three";
import ClientFabric from "../../lib/fabric"
import http from '../../lib/http'
import { maps } from '../../lib/definit'
import * as apis from '../../lib/apis'
import Title, { Action as TitleAction } from './plugins/title'
import Menu, { Action as MenuAction } from './plugins/menu'
import { Height as HeadHeight } from './plugins/head'
import { Height as InfoHeight, Bottom as InfoBottom } from './plugins/info'
import Loading from "./plugins/loading";
import Plugins from './plugins'
import Context from './context'
import { Height as CutpartHeight } from './plugins/cutpart'
import { Button, Toast, Drag, FixedNav } from '@nutui/nutui-react';
import OSS from 'ali-oss'
import { Events } from '../home/plugins/action_title'
import { sleep } from "../../common/tool";

const fclients = new Map()
const threeclients = new Map()

var temp3DheightCache: any = {}
var needHideTitle = false

const Index = (props: any) => {
    const params: any = useParams()
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [detailData, setDetailData] = useState<any>({ materials: { cutparts: [] } })
    const [curCutpartCode, setCurCutpartCode] = useState("")
    const [curCanvasClient, setCurCanvasClient] = useState<any>()
    const [curThreeClient, setCurThreeClient] = useState<any>()
    const [fabricClient, setFabricClient] = useState<any>(null) //fabric句柄
    const [threeClient, setThreeClient] = useState<Client3D>() //3D句柄
    const [controPosition, setControPosition] = useState<any>()
    const [editMode, setEditMode] = useState<any>()
    const [fabricW, setFabricW] = useState<number>()
    const [menuHeight, setMenuHeight] = useState<number>()
    const [curActivityObject, setCurActivityObject] = useState<any>()
    const [subMenuMode, onSubMenuMode] = useState<number>()
    const [showTitle, setShowTitle] = useState<boolean>(true)
    const [safeHeigth, setSafeHeigth] = useState<number>(-1)
    const [progress, setProgress] = useState<number>(0)
    const [objectModified, setObjectModified] = useState(0)
    const [previewMode, setPreViewMode] = useState<boolean>(false)
    const [visibleFixedNav, setVisibleFixedNav] = useState(false);
    const [curFabric, setCurFabric] = useState<any>({})
    const [action3D, setAction3D] = useState<any>({})
    const [ossClient, setOssClient] = useState({})
    const [curEvent, setCurEvent] = useState<Events>()



    const safeHeightRef: any = useRef()
    const menuRef: React.MutableRefObject<MenuAction | undefined> = useRef()

    const threeClientRef: any = useRef()


    //当code改变时，失去焦点
    useEffect(() => {
        curCanvasClient?.discardActiveObject()
        threeclients.get(curCutpartCode)?.activate()
    }, [curCutpartCode, curCanvasClient])


    //title滑动
    const titleChange = async (code: string, d = detailData) => {
        const fclient = fclients.get(code)
        if (!fclient) return
        const tclient = threeclients.get(code)
        if (!tclient) return

        let f = d?.materials?.cutparts?.find((i: any) => i.code === code)
        setCurCutpartCode(code)
        setCurCanvasClient(fclient)
        setCurThreeClient(tclient)
        if (f.camera) {
            threeClientRef?.current?.setCamera(f.camera.x, f.camera.y, f.camera.z)
        }
        // console.log(fclients)
        // await fclient.addImageById("https://file-1254182580.cos.ap-beijing.myqcloud.com/2021-12-10/1639141117721.png",300)
        // tclient.needsUpdate(500)
        return true
    }

    //更新当前更新模式
    const onEditMode = (mode: string) => {
        setEditMode(mode)
    }

    //预览3D
    const preview3D = () => {
        temp3DheightCache.height = threeClient?.getCanvasDom()?.clientHeight
        if (showTitle) {
            needHideTitle = true
            setShowTitle(false)
        }
        threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight)
        setPreViewMode(true)
    }

    //退出预览3D
    const quitPreview3D = () => {
        if (needHideTitle) {
            setShowTitle(true)
        }
        threeClient?.setCanvasSize(document.body.clientWidth, temp3DheightCache.height)
        needHideTitle = false
        setPreViewMode(false)
    }

    //面料切换
    const fixedNavOnChange = (e: any) => {
        setVisibleFixedNav(e)
    }

    //选择面料
    const choiceFabric = async (v: any, a: any, b: any) => {
        if (v.color === curFabric.color) {
            return
        }
        try {
            let cli: any
            if (a) {
                cli = a
            } else {
                cli = action3D
            }
            console.log("vvvvv", v)
            //先清除所有贴图
            if (v.codes_info && v.codes_info.length !== 0) {
                for (let i = 0; i < v.codes_info.length; i++) {
                    let item = v.codes_info[i]
                    if (item.maps) {
                        cli.clearMap(item.code)
                    }
                }
            }
            if (v.codes_info && v.codes_info.length !== 0) {
                for (let i = 0; i < v.codes_info.length; i++) {
                    let item = v.codes_info[i]
                    if (item.maps) {
                        for (let j = 0; j < item.maps.length; j++) {
                            let jitem = item.maps[j]
                            await cli.setMap(jitem.url, jitem.map, item.code)
                            await cli.openRepeat(item.code, true)
                            await cli.setMapOffset(item.code, -0.5, -0.5)
                            await cli.setMapRepeat(item.code, 20, 20)
                        }
                    }
                }
            }
            setCurFabric(v)
        } catch (error) {
            console.log(error)
            Toast.fail("底色加载失败")
        }
    }

    const sleep = (time: any) => {
        return new Promise((resolve: any, reject) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    }

    const getCanvasDom = async (code: string): Promise<HTMLElement | null> => {
        let c = document.getElementById(code)
        if (!c) {
            await sleep(100)
            return await getCanvasDom(code)
        }
        return c
    }

    useEffect(() => {
        //启动3D场景
        let ops: Client3DProps = {
            canvasDom: canvasRef?.current as HTMLCanvasElement,
            canvasWidth: 300,
            canvasHeight: 300
        }
        //初始化3DClient
        let client: Client3D = new Client3D(ops)
        setThreeClient(client)
        threeClientRef.current = client
        //启动3D场景
        client.start()

        //初始化fabricClient
        const fabricClient = new ClientFabric()
        setFabricClient(fabricClient)

        http.get(`${apis.DATA_DETAIL}?id=${params.id}`).then(async (data: any) => {
            data.materials = JSON.parse(data.materials)
            data.size = data.size.split(",")
            setDetailData(data)
            //等待dom加载完成
            // await sleep(500)
            const action3D: any = await client.loadModel(process.env.REACT_APP_OSS_URL + data.materials.obj_url, (p: number) => {
                setProgress(p)
            })
            setAction3D(action3D)

            //初始化所有fabrci句柄和3D句柄
            for (let i = 0; i < data?.materials?.cutparts?.length; i++) {
                let item = data?.materials?.cutparts[i]
                const fclient = fabricClient.loadCanvas(item.code)
                fclients.set(item.code, fclient)
                const cdom: any = await getCanvasDom(item.code)
                const tclient = action3D.setMapCanvasTextureByName(item.code, cdom as HTMLCanvasElement)
                threeclients.set(item.code, tclient)

                //开启所有2D与3D同步监听
                fclient?.onCanvas((e: any, controPosition: any, event: string) => {
                    if (event === "after:render") {
                        const acObj = fclient.getFabricCanvas().getActiveObject()
                        if (acObj) {
                            setCurActivityObject(acObj)
                        } else {
                            setCurActivityObject(null)
                        }
                        tclient.needsUpdate()
                        setControPosition(controPosition)
                        // console.log("ing")
                    }
                    if (event === "object:modified") {
                        setObjectModified(new Date().getTime())
                    }
                })
            }

            const menuHeight = menuRef.current?.getHeight!() as number
            setMenuHeight(menuHeight)
            const goldenRatio = document.body.clientHeight * 0.5
            // const w = document.body.clientHeight - goldenRatio - CutpartHeight - safeHeightRef.current.clientHeight - 80
            const w = document.body.clientHeight - goldenRatio - CutpartHeight - safeHeightRef.current.clientHeight - 80

            //设置裁片高度
            fabricClient.setWidth(w)
            setFabricW(w)
            setSafeHeigth(safeHeightRef.current.clientHeight)

            //设置3D的高度
            const canvas3DHeight = document.body.clientHeight - InfoHeight - menuHeight - InfoBottom - HeadHeight - 15
            client.setCanvasSize(document.body.clientWidth, canvas3DHeight)

            //如果有面料，使用第一个面料
            if (data?.materials?.map_info && data?.materials?.map_info?.length !== 0) {
                choiceFabric(data?.materials?.map_info[0], action3D, fabricClient)
            }

            //设置第一个部位活动
            if (data.materials.cutparts && data.materials.cutparts.length !== 0) {
                titleChange(data.materials.cutparts[0].code, data)
            }


        }).catch(error => {
            console.error(error)
            // Toast.fail(error.message)
        })

        return () => {
            client?.destroy()
        }
        //eslint-disable-next-line
    }, [params.id])

    return (
        <div style={{
            background: "linear-gradient(180deg, rgba(238,238,238,0) 0%, #F8F8F8 100%)",
            height: "100vh"
        }}>
            <div className="a" style={{ display: (detailData?.materials?.map_info && detailData?.materials?.map_info?.length > 1) ? "block" : "none" }}>
                <Drag direction="y" style={{ right: '0px', top: "20vh", zIndex: "999!important" }}>
                    <FixedNav
                        onChange={fixedNavOnChange}
                        visible={visibleFixedNav}
                        // navList={navList}
                        unActiveText="选择颜色"
                        activeText="收起颜色"
                        slotList={
                            <div className="nut-fixednav__list" slot="list" style={{ width: detailData?.materials?.map_info?.length * 25 || 0, marginRight: 8 }}>
                                {
                                    detailData?.materials?.map_info?.map((v: any) => (
                                        <div onClick={async () => {
                                            Toast.loading("加载中", {
                                                cover: true, // 是否展示透明遮罩层
                                                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                                                closeOnClickOverlay: false, // 点击遮罩可关闭
                                                duration: 0
                                            })
                                            if (detailData.materials.cutparts && detailData.materials.cutparts.length !== 0) {
                                                for (let i = 0; i < detailData.materials.cutparts.length; i++) {
                                                    let item = detailData.materials.cutparts[i]
                                                    action3D.clearMap(item.code)
                                                }
                                            }
                                            await choiceFabric(v, null, null)
                                            Toast.hide()
                                        }} key={v.color} style={{
                                            width: 20,
                                            height: 20,
                                            background: v.color,
                                            marginTop: 15,
                                            borderRadius: "50%",
                                            border: curFabric.color === v.color ? "solid 1px rgb(245, 119, 102)" : "solid 1px #fff"
                                        }}></div>
                                    ))
                                }
                            </div>
                        }
                    />
                </Drag>
            </div>
            <Context.Provider value={{
                detailData,
                curCanvasClient,
                curThreeClient,
                controPosition,
                editMode,
                onEditMode,
                fabricW,
                curCutpartCode,
                fabricClient,
                threeClient,
                menuHeight,
                curActivityObject,
                onSubMenuMode,
                subMenuMode,
                showTitle,
                setShowTitle,
                safeHeigth,
                objectModified,
                preview3D,
                curFabric,
                ossClient,
                setCurEvent,
                curEvent,
                titleChange
            }}>
                <Plugins>
                    <div className="displayAnimate" style={previewMode ? {
                        position: "fixed",
                        ...{ zIndex: 12, background: "linear-gradient(180deg, rgba(238,238,238,1) 0%, #F8F8F8 100%)", left: 0, top: 0, bottom: 0, right: 0, margin: "auto" }
                    } : { position: "relative" }}>
                        <canvas className="displayAnimate" ref={canvasRef}></canvas>
                        {
                            previewMode && <Button onClick={quitPreview3D} style={{
                                position: "fixed",
                                bottom: "5%",
                                left: 0,
                                right: 0,
                                margin: "auto",
                                width: 130,
                            }} type="primary">退出预览</Button>
                        }
                    </div>
                    <Menu action={menuRef} />
                    <Loading progress={progress} />
                    <div ref={safeHeightRef} style={{
                        height: "env(safe-area-inset-bottom)",
                        position: "fixed",
                        zIndex: -1,
                        bottom: 0,
                        left: 0
                    }}></div>
                </Plugins>
            </Context.Provider>
        </div>
    )
}

export default Index