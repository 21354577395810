
const loadedCache: any = {}

const loadFont = async (familyName: string, fontFamilyUrl: string) => {
    if (loadedCache[familyName]) {
        return
    }
    const { FontFaceObserver }: any = window
    const style = document.createElement('style');
    style.innerHTML = `
        @font-face {
            font-family: ${familyName};
            src: url("${fontFamilyUrl}");
        }
    `
    document.head.appendChild(style);
    let fontLoader = new FontFaceObserver(familyName)
    loadedCache[familyName] = 1
    return fontLoader.load(null, 30000)
}

export default loadFont

