import { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import http from '../../lib/http'
import * as apis from '../../lib/apis'
import { Toast } from '@nutui/nutui-react';


const Index = (props: any) => {
    const param = useParams()
    const l = useLocation()

    useEffect(() => {
        const urlParam = new URLSearchParams(l.search)
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        if (param.token) {
            localStorage.setItem("token", param.token)
            http.post(apis.PRODUCT_LIST, {
                page: 1,
                page_size: 1,
                status: 1
            }).then((patterns: any) => {
                if (patterns?.list && patterns?.list?.length !== 0) {
                    Toast.hide()
                    const path = urlParam.get("path")
                    if (path) {
                        window.location.replace(decodeURIComponent(path))
                    } else {
                        window.location.replace(`/design/${patterns?.list[0].id}`)
                    }

                }
            })
        }
    }, [])


    return (
        null
    )
}

export default Index