import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { Toast } from '@nutui/nutui-react';
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 1000 * 600,
});

export const wxAppid = "wx928ec14ad08905cf"

export function isWeChatBrowserOrMiniProgram(): "WeChat Browser" | "WeChat Mini Program" | "Not WeChat Browser or Mini Program" {
    if ((window as any).__wxjs_environment === 'miniprogram') {
        return "WeChat Mini Program"
    }
    return "WeChat Browser"

    // const userAgent = navigator.userAgent.toLowerCase();
    // const isWeChatBrowser = userAgent.includes('micromessenger') && !(window as any).__wxjs_environment;
    // const isWeChatMiniProgram = typeof (window as any).__wxjs_environment === 'object' && (window as any).__wxjs_is_mini_program;
    // if (isWeChatBrowser) {
    //     return 'WeChat Browser';
    // } else if (isWeChatMiniProgram) {
    //     return 'WeChat Mini Program';
    // } else {
    //     return 'Not WeChat Browser or Mini Program';
    // }
}
const redirect_uri = window.location.origin
export const redirectUri = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wxAppid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`

// 添加请求拦截器
instance.interceptors.request.use(function (config: any) {
    // 在发送请求之前做些什么
    config.headers = { 'Authorization': localStorage.getItem("token") }
    return config;
}, function (error: any) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response: AxiosResponse) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code === 401) {
        // Toast.fail("登录失效")
        if (isWeChatBrowserOrMiniProgram() === "WeChat Mini Program") {
            (window as any).wx.miniProgram.reLaunch({ url: `/pages/index/index` })
        } else {
            window.location.replace(redirectUri)
        }
        return
    }
    if (response.data.code !== 200) {
        Toast.fail(response.data.msg)
        return Promise.reject(response.data.msg);
    }
    return response.data?.data;
}, function (error: any) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    Toast.fail(error?.message)
    return Promise.reject(error);
});

export const uploadFile = (url: string, file: any, suffix: string = "png") => {
    return new Promise((resolve, reject) => {
        // 创建一个FormData对象
        const formData = new FormData();
        // 将文件添加到FormData对象中，'file'是后端接收的字段名
        formData.append('file', file, `file_${new Date().getTime()}.${suffix}`);
        // 使用Axios发送POST请求
        return axios.post(url, formData, {
            headers: {
                // 设置multipart/form-data头部，浏览器会自动设置合适的boundary
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    })
}

export default instance