import { useContext, useState, useImperativeHandle, useEffect } from 'react'
import OpenCutpartIcon from '../../../../static/img/more_edit.png'
import TContext from '../../context'
import PContext from '../context'
import { Popup, Button, Toast, Cell, Picker, Input, Icon, Radio } from '@nutui/nutui-react';
import http, { uploadFile, isWeChatBrowserOrMiniProgram, wxAppid } from '../../../../lib/http';
import * as apis from '../../../../lib/apis'
import md5 from 'md5'
import OSS from 'ali-oss'
import { Menu, MenuItem } from '@nutui/nutui-react';
import addressJson from '../../../../static/address.json'
import styled from "styled-components";
import { size } from 'lodash';

export const Height = 44
export const Bottom = 10

interface Props {
    action?: any
}

export interface Action {
    setShowPrice?: (isShow: boolean) => void;
    setShowSubmitOrder?: (isShow: boolean) => void;
    confirm?: () => boolean;
    cancel?: () => void;
}

const Index = (props: Props) => {
    const [isVisible, setIsVisible] = useState(false)
    const [receivedName, setReceivedName] = useState('')
    const [receivedPhone, setreceivedPhone] = useState('')
    const [receivedProvince, setReceivedProvince] = useState('')
    const [receivedProvinceCode, setReceivedProvinceCode] = useState('')
    const [receivedCity, setReceivedCity] = useState('')
    const [receivedCityCode, setReceivedCityCode] = useState('')
    const [receivedArea, setReceivedArea] = useState('')
    const [receivedAreaCode, setReceivedAreaCode] = useState('')
    const [address, setAddress] = useState('')
    const [size, setSize] = useState('')
    const [pickerShow, setPickerShow] = useState(false)
    const [showBasic, setShowBasic] = useState(false);
    const [showSubmitOrder, setShowSubmitOrder] = useState(false);
    const [loadingTxt, setLoadingTxt] = useState<string>("提交订单")
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    const openCutpart = () => {
        pc.openCutpart!()
    }

    const setShowPrice = (isShow: boolean) => {
        setShowBasic(isShow)
    }

    const doPay = async (orderNo: any) => {
        return new Promise(async (a: any, c: any) => {
            try {
                const jssdkInfo: any = await http.get(`${apis.WX_JSSDK}?appid=${wxAppid}&url=${encodeURIComponent(window.location.href)}`);
                (window as any).wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: jssdkInfo.appId, // 必填，公众号的唯一标识
                    timestamp: jssdkInfo.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jssdkInfo.nonceStr, // 必填，生成签名的随机串
                    signature: jssdkInfo.signature,// 必填，签名
                    jsApiList: [
                        "chooseWXPay",
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "updateAppMessageShareData",
                        "updateTimelineShareData"
                    ] // 必填，需要使用的 JS 接口列表
                });
                (window as any).wx.ready(async () => {
                    const payInfo: any = await http.post(`${apis.ORDER_PAY}?order_no=${orderNo}&type=wxgz`);
                    (window as any).wx.chooseWXPay({
                        timestamp: payInfo.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                        nonceStr: payInfo.nonceStr, // 支付签名随机串，不长于 32 位
                        package: payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: payInfo.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                        paySign: payInfo.paySign, // 支付签名
                        success: function () {
                            window.location.replace(`/order/list?tab=1`)
                        },
                        fail: function () {

                        }
                    });
                    a();
                });
                (window as any).wx.error(function (err: any) {
                    c(JSON.stringify(err))
                });


            } catch (error) {
                c(error)
                console.log(error)
            }
        })

    }

    useImperativeHandle(props.action, () => ({
        setShowPrice,
        setShowSubmitOrder
    }));

    useEffect(() => {
        const clickDom = () => {
            setPickerShow(false)
        }
        document.addEventListener("click", clickDom)
        return () => {
            document.removeEventListener("click", clickDom)
        }
    }, [])



    //计算价钱
    const cPrice = (): any => {
        let basePrice = parseFloat((tc?.detailData?.price / 100).toFixed(2))
        let frushPrice = 0
        let cutArr = []
        if (pc?.notEmptyCodes) {
            for (let i = 0; i < pc?.notEmptyCodes?.length; i++) {
                let code = pc?.notEmptyCodes[i]
                let f = tc?.detailData?.materials?.cutparts?.find((v: any) => v.code === code)
                if (f) {
                    basePrice += f.price
                    frushPrice += f.price
                    cutArr.push(f)
                }
            }
        }
        return [<span>¥ {basePrice}</span>, cutArr, frushPrice, basePrice]
    }

    const sleep = (t: number) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null)
            }, t)
        })
    }

    //生成并上传效果图
    const makeAndUploadImage = async (onProgress: any) => {
        //停止3D与2D的更新
        tc?.threeClient?.stopNeedsUpdate()
        const data: any = { effect_img: [], cutpart_img: [] }
        for (let i = 0; i < tc?.detailData?.materials?.cutparts?.length; i++) {
            let item = tc?.detailData?.materials?.cutparts[i]
            if (item.camera) {
                tc?.threeClient?.setCamera(item.camera.x, item.camera.y, item.camera.z)
            }
            await sleep(300)
            //获取blob
            const blob = await tc?.threeClient?.screePhoto()
            //上传效果图
            const ossData: any = await uploadFile(apis.UPLOAD, blob)
            if (!ossData.data.path) {
                Toast.fail("资源文件上传失败")
                return
            }
            data.effect_img.push({
                code: item.code,
                img: ossData.data.path
            })
            //上传裁片图 
            // const cutpartBlob = tc?.fabricClient?.toBlob(item.code, tc?.fabricW)
            // const uuid2 = await http.get(apis.UUID)
            // const filename2 = `files/${md5(uuid2)}.png`;
            // const ossData2 = await tc?.ossClient?.put(filename2, cutpartBlob)
            // if (!ossData2.name) {
            //     Toast.fail("资源文件上传失败")
            //     return
            // }
            // data.cutpart_img.push({
            //     code: item.code,
            //     img: ossData2.name
            // })
            onProgress(i / (tc?.detailData?.materials?.cutparts?.length - 1) * 100)
        }
        return data
    }

    //验证手机号
    function isValidPhoneNumber(phoneNumber: any) {
        // 中国大陆手机号的正则表达式
        const regex = /^1[3-9]\d{9}$/;
        return regex.test(phoneNumber);
    }

    //提交订单
    const submitOrder = async () => {
        if (tc?.curEvent) { //如果当前有事件未完成，不可提交订单
            Toast.warn("请先完成当前的编辑")
            return
        }
        if (!receivedName || !receivedPhone || !receivedProvince || !receivedCity || !receivedArea || !address) {
            Toast.warn("请填写收件信息")
            return
        }
        if (!size) {
            Toast.warn("请选择型号")
            return
        }
        if (!isValidPhoneNumber(receivedPhone)) {
            Toast.warn("手机号不正确")
            return
        }
        Toast.loading("提交中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })

        try {
            const mdata = await makeAndUploadImage((p: any) => {
                setLoadingTxt(p.toFixed(2) + "%")
            })

            //构造提交数据
            const postData: any = {
                received_name: receivedName,
                received_phone: receivedPhone,
                received_province: receivedProvince,
                received_province_code: receivedProvinceCode,
                received_city: receivedCity,
                received_city_code: receivedCityCode,
                received_area: receivedArea,
                received_area_code: receivedAreaCode,
                address,
                size
            }
            const resourceData: any = {}
            postData.pruduct_id = tc?.detailData?.id
            postData.price_str = cPrice()[3].toFixed(2)
            if (pc?.notEmptyCodes && pc?.notEmptyCodes?.length !== 0) {
                postData.printing_code = JSON.stringify(pc?.notEmptyCodes)
            }
            if (tc?.curFabric?.color) {
                postData.color = tc?.curFabric?.color
            }
            resourceData.canvas_width = tc?.fabricW as number
            resourceData.canvas_data = tc?.fabricClient?.allToJson()
            for (let i = 0; i < resourceData.canvas_data.length; i++) {
                let item = resourceData.canvas_data[i]
                let f = tc?.detailData?.materials?.cutparts?.find((j: any) => j.code === item.code)
                if (f) {
                    item.name = f.txt
                }
            }

            resourceData.model_url = process.env.REACT_APP_OSS_URL + tc?.detailData?.materials?.obj_url
            resourceData.map_info = tc?.curFabric
            resourceData.materials = mdata


            const ossData: any = await uploadFile(apis.UPLOAD, new Blob([JSON.stringify(resourceData)], { type: 'text/plain' }), "json")
            if (!ossData.data.path) {
                Toast.fail("提交失败,请重试")
                Toast.hide()
                return
            }
            try {
                postData.cover = mdata.effect_img[0].img
            } catch (error) {

            }
            postData.resource_url = ossData.data.path
            const orderNo = await http.post(apis.ORDER_CREATE, postData)
            if (isWeChatBrowserOrMiniProgram() === "WeChat Mini Program") {
                const { wx } = window as any
                wx.miniProgram.reLaunch({ url: `/pages/pay/pay?order_no=${orderNo}` })
            } else {
                await doPay(orderNo)
            }
            setShowBasic(false)
            setLoadingTxt("提交订单")
        } catch (error) {
            console.log(error)
            Toast.fail("提交失败,请重试")
            setLoadingTxt("提交订单")
        }
        Toast.hide()

    }

    const optionDataHandle = () => {
        const arr = []
        for (let i = 0; i < tc.detailData.materials.cutparts.length; i++) {
            let item = tc.detailData.materials.cutparts[i]
            arr.push({ text: item.txt, value: item.code })
        }
        if (!tc.curCutpartCode) {
            return []
        }
        return arr
    }


    const menuChange = (val: any) => {
        tc?.titleChange(val.value)
    }

    const cutpartOptions = optionDataHandle()

    const getOptionTxt = (code: any) => {
        const f = cutpartOptions.find(v => v.value === code)
        if (f) {
            return f.text
        }
        return ""
    }


    return (
        <div style={{
            position: "fixed",
            bottom: (tc?.menuHeight as number + Bottom) || 0,
            width: "100%",
            left: 0,
            right: 0,
            margin: "auto",
            height: Height
        }}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 20px"
            }}>
                <div>
                    <div onClick={() => {
                        setShowBasic(true);
                        setShowSubmitOrder(false)
                    }} style={{
                        color: "#F57766"
                    }}><span style={{
                        fontWeight: 600,
                        fontSize: 14
                    }}>{cPrice()[0]}</span> <span style={{
                        fontSize: 12
                    }}>查看明细</span></div>
                    <div style={{
                        fontSize: 12,
                        color: "#333333",
                        marginTop: 4,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 220
                    }}>款式：{tc?.detailData?.name}</div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setPickerShow(!pickerShow)
                    }} style={{
                        width: 80,
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        fontSize: 14,
                        position: "relative"
                    }}>
                        {
                            pickerShow && <OptionWrapper>
                                {
                                    cutpartOptions.map(v => (
                                        <Option onClick={(e: any) => {
                                            e.stopPropagation()
                                            tc?.titleChange(v.value)
                                            setPickerShow(false)
                                        }} $active={v.value === tc?.curCutpartCode} key={v.value}>{v.text}</Option>
                                    ))
                                }
                            </OptionWrapper>
                        }
                        <OptionLabel>{getOptionTxt(tc?.curCutpartCode)}</OptionLabel>
                        <Icon size={14} name="screen-little"></Icon>
                    </div>
                    <img style={{
                        width: 30,
                        height: 30,
                        display: "block",
                        borderRadius: "50%",
                        marginLeft: 12
                    }} onClick={openCutpart} src={OpenCutpartIcon} alt="" />
                </div>
            </div>
            <Popup
                closeIconPosition="top-left"
                visible={showBasic}
                teleport={document.body}
                overlayStyle={{ zIndex: 13 }}
                closeable
                style={{ height: '90vh' }}
                position="bottom"
                onClose={() => {
                    // console.log(loadingTxt, loadingTxt !== "提交订单")
                    // if (loadingTxt !== "提交订单") {
                    //     return
                    // }
                    setShowBasic(false)
                }}
            >
                <div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 500,
                        height: 45,
                        lineHeight: "45px",
                        textAlign: "center"
                    }}>{showSubmitOrder ? "下单" : "价格明细"}</div>
                    <div style={{
                        overflow: "auto",
                        height: "calc(90vh - 100px)"
                    }}>
                        {
                            showSubmitOrder && <div>
                                <Input defaultValue={receivedName} label="姓名" placeholder="请输入" onChange={(val) => { setReceivedName(val) }} />
                                <Input defaultValue={receivedPhone} onChange={(val) => { setreceivedPhone(val) }} label="手机号" placeholder="请输入" />
                                <AddressChoice onClick={() => {
                                    setIsVisible(true)
                                }}>
                                    <div style={{ flexShrink: 0, width: 86 }}>选择地址</div>
                                    <div>{receivedProvince ? `${receivedProvince} ${receivedCity} ${receivedArea}` : <span style={{ color: "gray" }}>请选择</span>}</div>
                                </AddressChoice>
                                <Input
                                    defaultValue={address}
                                    label="详细地址"
                                    placeholder="请输入详细地址"
                                    type="textarea"
                                    showWordLimit
                                    rows="2"
                                    maxlength="50"
                                    onChange={(val) => { setAddress(val) }}
                                />
                                <Picker
                                    isVisible={isVisible}
                                    listData={addressJson}
                                    onClose={() => setIsVisible(false)}
                                    onConfirm={(values, list: any) => {
                                        for (let i = 0; i < list.length; i++) {
                                            const item = list[i]
                                            if (i === 0) {
                                                setReceivedProvince(item.text)
                                                setReceivedProvinceCode(item.value)
                                            }
                                            if (i === 1) {
                                                setReceivedCity(item.text)
                                                setReceivedCityCode(item.value)
                                            }
                                            if (i === 2) {
                                                setReceivedArea(item.text)
                                                setReceivedAreaCode(item.value)
                                            }
                                        }
                                    }}
                                />
                                <CellWrapper>
                                    <LeftLabel>型号</LeftLabel>
                                    <Radio.RadioGroup value={size} onChange={(e: any) => {
                                        setSize(e)
                                    }} direction="horizontal">
                                        {
                                            tc?.detailData?.size.map((v: any) => (
                                                <Radio shape="button" key={v} value={v}>{v}</Radio>
                                            ))
                                        }
                                    </Radio.RadioGroup>
                                </CellWrapper>
                            </div>
                        }
                        <CellWrapper>
                            <LeftLabel style={{
                                color: "#f37972"
                            }}>总价</LeftLabel>
                            <span style={{
                                color: "#f37972",
                                fontSize: 14
                            }}>¥ {cPrice()[3]?.toFixed(2)?.split(".")[0]}<span style={{
                                color: "#f37972",
                                fontSize: 12
                            }}>.{cPrice()[3]?.toFixed(2)?.split(".")[1]}</span></span>
                        </CellWrapper>
                        <CellWrapper>
                            <LeftLabel>商品单价</LeftLabel>
                            <span style={{
                                color: "#333333",
                                fontSize: 14
                            }}>¥ {(tc?.detailData?.price / 100)?.toFixed(2)?.split(".")[0]}<span style={{
                                color: "#333333",
                                fontSize: 12
                            }}>.{(tc?.detailData?.price / 100)?.toFixed(2)?.split(".")[1]}</span></span>
                        </CellWrapper>
                        {
                            cPrice()[1]?.map((v: any) => (
                                <CellWrapper key={v.code}>
                                    <LeftLabel>{v.txt}印刷</LeftLabel>
                                    <span style={{
                                        color: "#333333",
                                        fontSize: 14
                                    }}>¥ {(v.price)?.toFixed(2)?.split(".")[0]}<span style={{
                                        color: "#333333",
                                        fontSize: 12
                                    }}>.{(v.price)?.toFixed(2)?.split(".")[1]}</span></span>
                                </CellWrapper>
                            ))
                        }
                        {/* {
                            cPrice()[2] !== 0 && <CellWrapper>
                                <LeftLabel>印刷总价</LeftLabel>
                                <span style={{
                                    color: "#333333",
                                    fontSize: 16
                                }}>¥ {(cPrice()[2])?.toFixed(2)?.split(".")[0]}<span style={{
                                    color: "#333333",
                                    fontSize: 14
                                }}>.{(cPrice()[2])?.toFixed(2)?.split(".")[1]}</span></span>
                            </CellWrapper>
                        } */}
                    </div>
                    {
                        showSubmitOrder && <div style={{ textAlign: "center" }}>
                            <Button loading={loadingTxt !== "提交订单"} onClick={submitOrder} style={{ background: "rgb(245, 119, 102)" }} type="primary">{loadingTxt}</Button>
                        </div>
                    }
                </div>
            </Popup>
        </div>
    )
}

const LeftLabel = styled.div`
    color: #1a1a1a;
    font-size: 14px;
    flex-shrink: 0;
    width: 80px;
`

const AddressChoice = styled.div`
    display: flex;
    color: #1a1a1a;
    font-size: 14px;
    padding-left: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--nutui-input-border-bottom, #eaf0fb);
`

const CellWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    margin-top: 12px;
`

const OptionWrapper = styled.div`
    position: absolute;
    bottom: 34px;
    width: 80px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 1px 2px 4px #ccc;
    overflow: hidden;
`
interface OptionProps {
    $active: boolean;
}

const Option = styled.div<OptionProps>`
    font-size: 14px;
    color: ${(props: any) => props.$active ? "#FFF" : "#333"};
    text-align: center;
    padding: 6px;
    background: ${(props: any) => props.$active ? "linear-gradient(135deg, var(--nutui-brand-color, #fa2c19) 0%, var(--nutui-brand-color-end, #fa2c19) 100%)" : "none"};
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
`

const OptionLabel = styled.div`
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    margin-right: 4px;
`

export default Index